import NewsCard from './../home/newsCard'
import LooksRare from '../../assets/images/home/LooksRare.png'

function NewsList() {
  return ( 
    <div className="container">
      <div className="row">
        <div className="col-12">
          <h2 className='common-heading text-center my-lg-5 my-3'>News <span>List</span></h2>
        </div>
        <div className='col-md-4 col-sm-12'>
            <NewsCard
              cardimage={LooksRare}
              title="What is Lorem Ipsum?"
              text="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been"
              link="/news-detail"                  
            />
        </div>
        <div className='col-md-4 col-sm-12'>
          <NewsCard
            cardimage={LooksRare}
            title="What is Lorem Ipsum?"
            text="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been"
            link="/news-detail"                  
          />
        </div>
        <div className='col-md-4 col-sm-12'>
          <NewsCard
            cardimage={LooksRare}
            title="What is Lorem Ipsum?"
            text="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been"
            link="/news-detail"                  
          />
        </div>
        <div className='col-md-4 col-sm-12'>
            <NewsCard
              cardimage={LooksRare}
              title="What is Lorem Ipsum?"
              text="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been"
              link="/news-detail"                  
            />
        </div>
        <div className='col-md-4 col-sm-12'>
          <NewsCard
            cardimage={LooksRare}
            title="What is Lorem Ipsum?"
            text="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been"
            link="/news-detail"                  
          />
        </div>
        <div className='col-md-4 col-sm-12'>
          <NewsCard
            cardimage={LooksRare}
            title="What is Lorem Ipsum?"
            text="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been"
            link="/news-detail"                  
          />
        </div>
        <div className='col-md-4 col-sm-12'>
            <NewsCard
              cardimage={LooksRare}
              title="What is Lorem Ipsum?"
              text="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been"
              link="/news-detail"                  
            />
        </div>
        <div className='col-md-4 col-sm-12'>
          <NewsCard
            cardimage={LooksRare}
            title="What is Lorem Ipsum?"
            text="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been"
            link="/news-detail"                  
          />
        </div>
        <div className='col-md-4 col-sm-12'>
          <NewsCard
            cardimage={LooksRare}
            title="What is Lorem Ipsum?"
            text="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been"
            link="/news-detail"                  
          />
        </div>
      </div>
    </div>
   );
}

export default NewsList;