import React, { useState } from 'react';
import { Button } from "reactstrap";
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import GovernanceTab from "./governanceTab";
import './governance.scss';

const Governance = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return ( 
    <div>
      <div className="governance">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-10 col-md-12">
              <div className="gov-header">
                <h2>Governance</h2>
                <Button onClick={handleShow}>New proposal</Button>
              </div>
              <GovernanceTab/>
            </div>
          </div>          
        </div>        
      </div>
      {/* Create proposal modal  */}
      <Modal className='proposal-modal' size="lg" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Create proposal</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>Proposal title</Form.Label>
              <Form.Control
                type="text"
                placeholder="Write proposal title here"                
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>What is your proposal?</Form.Label>
              <Form.Control placeholder='What is your proposal?' as="textarea" rows={3} />
            </Form.Group>
            <div className='row'>
              <div className='col-sm-6 col-12'>
                <Form.Group>
                  <Form.Label>Start Date</Form.Label>
                    <Form.Control
                      className='dateinput'
                      type="date"
                      placeholder="Write proposal title here"                
                    />
                </Form.Group>
              </div>
              <div className='col-sm-6 col-12'>
                <Form.Group>
                  <Form.Label>End Date</Form.Label>
                    <Form.Control
                      className='dateinput'
                      type="date"
                      placeholder="Write proposal title here"                
                    />
                </Form.Group>
              </div>
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Submit proposal
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
   );
}
 
export default Governance;