import React from "react";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";

// image 
import Arrow from '../../../assets/images/btn-arrow.svg'

const HeadingTextBtn = (props) => {
  const openInNewTab = (url) => {
    window.open(url, '_blank', 'noreferrer');
  };
  return ( 
    <div className="heading-with-btn">
      <h1>{props.heading}<span> {props.heading2}</span>{props.heading3}</h1>
      <p>{props.subtext}</p>
      <div className="btn-outer">
          <Button onClick={() => openInNewTab(props.btnlink)} className={props.btnclass}>{props.btntext}<span className={props.arrowclass}><img src={Arrow} alt="arrow"></img></span></Button>
        {props.children}
      </div>
    </div>
   );
}
 
export default HeadingTextBtn;