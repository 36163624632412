import React, { useRef, useState } from "react"
import { Button } from 'reactstrap';
import { Link, useLocation } from 'react-router-dom';
import ReactPlayer from 'react-player';
import HeadingTextBtn from '../../components/common/headinTextBtn/headingTextBtn';
import TokenomicsHomeTab from './tokenomicsHomeTab';
import ExplorerCard from './explorerCard'
import NewsCard from './newsCard'
// animation
import { Fade } from "react-awesome-reveal";
import { Slide } from "react-awesome-reveal";




// images
import DownArrow from '../../assets/images/home/down.svg'
import Earn from '../../assets/images/home/earn.png'

import LooksRare from '../../assets/images/home/LooksRare.png'
// import DogCard from '../../assets/images/home/dogcard.png'
import Twitter from '../../assets/images/home/social-1.svg'
import Telegram from '../../assets/images/home/social-2.svg'
import Discord from '../../assets/images/home/social-3.svg'
import Instagram from '../../assets/images/home/social-4.svg'
import Medium from '../../assets/images/home/social-5.svg'
import Reddit from '../../assets/images/home/social-6.svg'
import PlayBtn from '../../assets/images/home/play-btn.svg'
import VideoImage from '../../assets/images/home/video.png'
import ArrowBlue from '../../assets/images/home/arrow-blue.svg'
import LogoBig from '../../assets/images/logo/logo2.svg'
import Copy from '../../assets/images/copy.svg'
import Arrow from '../../assets/images/btn-arrow-dark.svg'
import DOERangers from '../../assets/images/home/product/1.png'
import KudoeVerse from '../../assets/images/home/product/3.png'
import DOELeash from '../../assets/images/home/product/4.png'
import KudoeDAO from '../../assets/images/home/product/5.png'
import KudoeNFTMarketplace from '../../assets/images/home/product/6.png'
import Yahoo from '../../assets/images/yahoo.png'
import Market from '../../assets/images/market.png'
import Benzinga from '../../assets/images/benzinga.png'
import Digital from '../../assets/images/digital.png'
import TechBullion from '../../assets/images/TechBullion.png'

// partner
// import Bitmart from '../../assets/images/home/partners/Bitmart.svg'
import CMC from '../../assets/images/home/partners/CMC.svg'
import CoinGeko from '../../assets/images/home/partners/CoinGeko.svg'
import gate from '../../assets/images/home/partners/gate.svg'
import Hotbit from '../../assets/images/home/partners/Hotbit.svg'
import LBANK from '../../assets/images/home/partners/LBANK.svg'
import MEXC from '../../assets/images/home/partners/MEXC.svg'
import guardarian from "../../assets/images/home/partners/Guardarian.svg"
import changenow from "../../assets/images/home/partners/ChangeNOW.svg"
import bittrue from "../../assets/images/home/partners/Bitrue.svg"
import web3 from "../../assets/images/home/partners/web3.svg"
import opensea from '../../assets/images/home/partners/opensea.svg'
import poloniex from '../../assets/images/home/partners/poloniex.svg'
import Sandbox from '../../assets/images/home/partners/Sandbox.svg'
import Uniswap from '../../assets/images/home/partners/Uniswap.svg'
import Unicrypt from '../../assets/images/home/partners/Unicrypt.png'
// import ZT from '../../assets/images/home/partners/ZT.svg'
import UnknownPartner from '../../assets/images/home/partners/unknown.svg'

// video
import BannerVideo from '../../assets/video/hero-desktop_HQ.mp4'
import BannerVideoMobile from '../../assets/video/hero-mobiles_HQ.mp4'

//news
import news1 from '../../assets/images/home/news/1.webp'
import news2 from '../../assets/images/home/news/2.png'
import news3 from '../../assets/images/home/news/3.webp'
// pdf
import WP from '../../assets/docs/Kudoe_Whitepaper.pdf'

// css
import './home.scss';


const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop)

const Home = () => {
  const location = useLocation();
  const [copyText, setCopyText] = useState("Copy")
  const [copyTextBSC, setCopyTextBSC] = useState("Copy")
  React.useEffect(()=> {
    if (location.hash) {
        let elem = document.getElementById(location.hash.slice(1))
        if (elem) {
            elem.scrollIntoView({behavior: "smooth"})
        }
    } else {
    window.scrollTo({top:0,left:0, behavior: "smooth"})
    }
}, [location,]);
  const openInNewTab = (url) => {
    window.open(url, '_blank', 'noreferrer');
  };
  const videoSectionRef = useRef(null)
  const executeScroll = () => scrollToRef(videoSectionRef)
  return (
    <div>
      <div className='home-banner'>
        <div className='container position-relative'>
          <div className='banner-bg-video'>
            <video width="100%" height="800" className='d-md-block d-none' autoPlay muted playsInline>
              <source src={BannerVideo} type="video/mp4" />
            </video>
            <video width="100%" height="300" className='d-md-none d-block' autoPlay muted playsInline>
              <source src={BannerVideoMobile} type="video/mp4" />
            </video>
          </div>
          <Slide direction={`left`} delay={1750} triggerOnce={true}>
            <div className="row">
              <div className='col-xl-5 col-lg-6 col-md-7 col-sm-12'>
                <div className='svg-banner'>
                  <img src={LogoBig} alt="logo" className='img-fluid' />
                </div>
                <HeadingTextBtn
                  subtext="Empowering the future of web3 with transformative gaming, finance, and governance solutions"
                  btnclass="blue_btn h70 plr-30"
                  btnlink="https://kudoe.game/"
                  btntext="&nbsp;&nbsp;&nbsp;&nbsp;Play DOE Rangers&nbsp;&nbsp;&nbsp;&nbsp;"
                  arrowclass="d-none"
                >
                  {/* <Link to="/buy">
                    <Button className='blue_btn h70 plr-30 ms-2 white_btn'>Buy on Uniswap</Button>
                  </Link> */}
                </HeadingTextBtn>
                <div className='scroll-down'>
                  <div onClick={executeScroll}>
                    <img src={DownArrow} alt="arrow" />
                    <span>Scroll down</span>
                  </div>
                </div>
              </div>
            </div>
          </Slide>
        </div>
      </div>
      {/* video section */}

      <div id="Gaming" className='videosection' ref={videoSectionRef}>
        <div className='container'>
          <div className='videosection-inner'>
            <div className='row align-items-center'>
              <div className='col-lg-7 col-md-12'>
                <div className='heading-with-btn'>
                  <h1>Putting the fun into blockchain gaming.</h1>
                  <p>Blockchain gaming should be fun and innovative, putting players first, tokenomics second. Play, Compete and immerse yourself with our innovative and community first ecosystem.</p>
                  <Button onClick={() => openInNewTab('https://kudoe-tm.gitbook.io/kudoe-tm/utilities/kudoe-gaming')} className="blue_btn h60 arrow white_btn">Learn More<span><img src={ArrowBlue} alt="arrow"></img></span></Button>
                  <Button onClick={() => openInNewTab('https://t.me/KudoeOfficial')} className="blue_btn h60 plr-30 ms-sm-2 ms-0 mt-sm-0 mt-2 white_outline btn btn-secondary">Join our Telegram</Button>
                </div>
              </div>
              <div className='col-lg-5 col-md-12'>
                <ReactPlayer
                  url="https://www.youtube.com/watch?v=o3c0i16q3C8"
                  width='100%'
                  className="react-player"
                  playing
                  playIcon={<img className='player-icon' src={PlayBtn} alt="icon" />}
                  light={VideoImage}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* section 2 */}
      <div className='home-section-2 mt-lg-5 mt-3'>
        <div className='container'>
          <div className='row align-items-center'>
            <div className='col-lg-6 col-md-6 col-sm-12 text-md-left text-center mb-md-0 mb-4 order-md-2'>
              <Slide direction={`right`} delay={50} triggerOnce={true}>
                <div className='partner_section'>
                  <div>
                    <a href='https://app.uniswap.org/#/swap?inputCurrency=0x5f190f9082878ca141f858c1c90b4c59fe2782c5&outputCurrency=ETH'><img src={Uniswap} alt="partner" /></a>
                    <a href='https://guardarian.com/buy-kdoe'><img src={guardarian} alt="partner" /></a>
                    <a href='https://changenow.io/currencies/kudoe'><img src={changenow} alt="partner" /></a>
                    <a href='https://poloniex.com/spot/kdoe_USDT/'><img src={poloniex} alt="partner" /></a>
                  </div>
                  <div>
                    <a href='https://www.hotbit.io/exchange?symbol=KDOE_USDT'><img src={Hotbit} alt="partner" /></a>
                    <a href='https://www.bitrue.com/trade/kdoe_usdt'><img src={bittrue} alt="partner" /></a>
                    <a href=''><img src={Sandbox} alt="partner" /></a>
                    <a href='https://opensea.io/collection/dogs-of-elon' target="_blank"><img src={opensea} alt="partner" /></a>
                  </div>
                  <div>
                    <a href='https://coinmarketcap.com/currencies/kudoe/' target="_blank"><img src={CMC} alt="partner" /></a>
                    <a href='https://www.coingecko.com/en/coins/kudoe' target="_blank"><img src={CoinGeko} alt="partner" /></a>
                    <a href='https://app.unicrypt.network/amm/uni-v2/pair/0xdd8F50f1059c50D7D75fE2f5D7d262964fB2Ae98' target="_blank"><img src={Unicrypt} alt="partner" /></a>
                    <a href='https://www.web3nycgallery.com/'><img src={web3} alt="partner" /></a>
                  </div>
                </div>
              </Slide>
            </div>
            <div className='col-lg-6 col-md-6 col-sm-12 ps-lg-5'>
              <Slide direction={`left`} delay={100} triggerOnce={true}>
                <HeadingTextBtn
                  heading="Our Ecosystem"
                  heading2="Partners"
                  subtext="We have partnered with leading web3 projects and companies in the blockchain industry to create a dynamic and vibrant ecosystem that is based on decentralized practicality, utility, and a strong community."
                  btnclass="blue_btn h60 arrow dark_btn"
                  btnlink="https://kudoe-tm.gitbook.io/kudoe-tm/get-started/our-partners"
                  btntext="Our Partners"
                >
                  <Link to="/buy">
                    <Button className='blue_btn h60 arrow dark_btn_outline btn btn-secondary ms-sm-2 ms-0 mt-sm-0 mt-2'>Buy<span><img src={Arrow} alt="arrow"></img></span></Button>
                  </Link>
                </HeadingTextBtn>
              </Slide>
            </div>

          </div>
        </div>
      </div>
      {/* Tokenomics tabs section  */}
      <div className='nft-tab-outer'>
        <div className='nft-tab'>
          <div className='container'>
            <TokenomicsHomeTab />
          </div>
        </div>
      </div>

      {/* utilities card section  */}
      <div className='card-home-section pt-lg-5 pt-3'>
        <div className='container'>
          <div className='row'>
            <div className='col-12 pt-lg-5 pb-lg-5 pb-3'>
              <h2 className='common-heading  pb-lg-5'>Our Products</h2>
            </div>
            <div className='col-lg-6 col-md-6 col-sm-6 col-12 pe-lg-5' style={{display: "flex", flexDirection: "column", justifyContent: "center"}}>
              <ExplorerCard
                cardimage={DOERangers}
                title="DOE Rangers"
                link="https://kudoe-tm.gitbook.io/kudoe-tm/utilities/kudoe-gaming/doe-rangers"
                text="The Dogs of Elon collection of 10,000 NFTs is available on the world's largest NFT Marketplace. Browse, favorite and place your bids for your favorite DOE Doge!"
              />
              <ExplorerCard
                cardimage={KudoeVerse}
                title="KudoeVerse"
                link="https://kudoe-tm.gitbook.io/kudoe-tm/utilities/kudoe-gaming/kudoeverse"
                text="KudoeVerse is an immersive metaverse multiplayer gaming experience where players can connect with each other and engage in a variety of activities, games and events."
              />
              <ExplorerCard
                cardimage={KudoeDAO}
                title="Kudoe DAO"
                link="https://kudoe-tm.gitbook.io/kudoe-tm/utilities/kudoe-dao"
                text="Participate in the governance of KDOE tokens through the Kudoe DAO, which will oversee key governance decisions concerning tokenomics, grants, and rewards."
              />
            </div>
            <div className='col-lg-6 col-md-6 col-sm-6 col-12 ps-lg-5' style={{display: "flex", flexDirection: "column", justifyContent: "center"}}>
              <ExplorerCard
                cardimage={DOELeash}
                title="DOE Off the Leash"
                link="https://kudoe-tm.gitbook.io/kudoe-tm/utilities/kudoe-gaming/doe-off-the-leash"
                text="DOE off the Leash is a unique and immersive single player Sandbox RPG adventure where gamers complete a set of objectives and tasks in search of the Dogefather."
              />
              <ExplorerCard
                cardimage={KudoeNFTMarketplace}
                title="Kudoe NFT Marketplace"
                link="https://kudoe-tm.gitbook.io/kudoe-tm/utilities/nft-marketplace"
                text="A Marketplace where you can browse and buy Dogs of Elon NFTs for a slightly lower fee."
              />
            </div>
          </div>
        </div>
      </div>
      {/* news section  */}
      <div className='news-section'>
        <div className='container'>
          <div className='row'>
            <div className='col-12 mt-lg-5 mt-4 mb-lg-5 mb-2'>
              <h2 className='common-heading text-center'>News</h2>
            </div>
            <div className='col-md-4 col-sm-12'>
              <NewsCard
                cardimage={news1}
                title="Introducing DOE Rangers SZN3"
                text="Kudoe is thrilled to announce DOE Rangers SZN3 which is due to launch on Monday December 11th, 2023 in collaboration with…"
                link="https://medium.com/dogs-of-elon-community/kudoe-whitepaper-launch-c4f937a0f932"
              />
            </div>
            <div className='col-md-4 col-sm-12'>
              <NewsCard
                cardimage={news2}
                title="Kudoe NFT (DOE) Staking"
                text="Kudoe is pleased to announce the upcoming launch of DOE NFT Staking, scheduled to commence on Thursday, February 23rd, 2023…"
                link="https://medium.com/kudoe/kudoe-nft-doe-staking-f45283463d0b"
              />
            </div>
            <div className='col-md-4 col-sm-12'>
              <NewsCard
                cardimage={news3}
                title="Kudoe x Guardarian"
                text="Kudoe is excited to announce a partnership with Guardarian, a leading provider of fiat on-ramp solutions for the cryptocurrency world. This…"
                link="https://medium.com/dogs-of-elon-community/kudoe-x-guardarian-11d06da3f88e"
              />
            </div>
            {/* <div className='col-12 text-center mt-lg-5 mt-3'>
              <Link to="/news-list">
                <button className='blue_btn h50 dark_btn btn btn-secondary px-4'>Show All</button>
              </Link>
            </div> */}
          </div>
        </div>
      </div>
      {/* Join Our Community section */}
      <div id="Community" className='join-community'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-7 col-md-10 col-sm-12'>
              <Slide direction={`left`} triggerOnce={true}>
                <h2 className="common-heading white mb-lg-5 mb-4">Join Our Community</h2>
                <p className='w-lg-50'>Join the Kudoe community by following us on our social media accounts. Stay up to date with the latest news and updates, connect with other members of the community, and engage with us on a variety of topics related to the project.</p>
                <div className='social-links'>
                  <Fade triggerOnce={true} cascade damping={0.08}>
                    <a href="https://twitter.com/KudoeOfficial" target="_blank"><img src={Twitter} alt="icon" /></a>
                    <a href="https://t.me/KudoeOfficial" target="_blank"> <img src={Telegram} alt="icon" /></a>
                    <a href="https://discord.gg/kudoe" target="_blank"> <img src={Discord} alt="icon" /></a>
                    <a href="https://www.instagram.com/kudoe_official" target="_blank"> <img src={Instagram} alt="icon" /></a>
                    <a href="https://medium.com/kudoe" target="_blank"> <img src={Medium} alt="icon" /></a>
                    <a href="https://www.reddit.com/r/Kudoe/" target="_blank"> <img src={Reddit} alt="icon" /></a>
                  </Fade>
                </div>
                <p className='mt-lg-5 mt-3 mb-lg-4 mb-3'><b>As Seen in the News</b></p>
                <div className='seen_news'>
                  <a href='https://finance.yahoo.com/news/dogs-elon-kudoe-top-dog-061000524.html' target="_blank"><img src={Yahoo} alt="icon" className='img-fluid' /></a>
                  <a href='https://www.marketwatch.com/press-release/from-dogs-of-elon-to-kudoe---theres-a-new-top-dog-in-town-2022-12-14?mod=search_headline' target="_blank"><img src={Market} alt="icon" className='img-fluid' /></a>
                  <a href='https://www.digitaljournal.com/pr/from-dogs-of-elon-to-kudoe-theres-a-new-top-dog-in-the-town' target="_blank"><img src={Digital} alt="icon" className='img-fluid' /></a>
                  <a href='https://www.benzinga.com/pressreleases/23/03/ab31262440/kudoe-launches-dao-and-continues-to-build-strong-ecosystem-with-new-collaborations-and-partnershi' target="_blank"><img src={Benzinga} alt="icon" className='img-fluid' /></a>
                </div>
              </Slide>
            </div>

          </div>
        </div>

      </div>
    </div>
  );
}

export default Home;
