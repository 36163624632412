import React from "react";
import Card from 'react-bootstrap/Card';
import { Link } from "react-router-dom";
import { Fade } from "react-awesome-reveal";
const NewsCard = (props) => {
  if (props.link.startsWith("http")) {
    return (
      <Fade triggerOnce={true} duration={`2000`}>
        <a href={props.link} target="_blank">
          <Card className="newscard">
            <Card.Img variant="top" src={props.cardimage} />
            <Card.Body>
              <Card.Title>{props.title}</Card.Title>
              <Card.Text>
                {props.text}
              </Card.Text>
              <a href={props.link} target="_blank">Read More</a>
            </Card.Body>
          </Card>
        </a>
      </Fade>
    );
  } else {
    return (
      <Fade triggerOnce={true} duration={`2000`}>
        <Link to={props.link}>
          <Card className="newscard">
            <Card.Img variant="top" src={props.cardimage} />
            <Card.Body>
              <Card.Title>{props.title}</Card.Title>
              <Card.Text>
                {props.text}
              </Card.Text>
              <a href={props.link} target="_blank">Read More</a>
            </Card.Body>
          </Card>
        </Link>
      </Fade>
    );
  }
}

export default NewsCard;