import { useState, useEffect } from "react"
import { useEthereum } from "../hooks/useEthereum"
import { Button } from "reactstrap"

import { formatEther, parseEther } from "@ethersproject/units"
import Info from "../assets/images/staking/info-icon.svg"

import { formatNumber } from "../helpers/string"
import { useModal } from "../hooks/useModal"
import ConnectModal from "./modal/ConnectModal"
import TransactionModal from "./modal/TransactionModal"
import ErrorModal from "./modal/ErrorModal"
import StakeModal from "./modal/StakeModal"
import ConnectWalletModal from "./modal/ConnectWalletModal"
import { getNFTs, getNftUrl } from "../helpers/getNFTs"
import { useGlobalApr } from "../store/globalApr"
import Select from "react-select"

const CustomNFTStakingCard = ({
  stakedNftId,
  nftContract,
  stakingContract,
  getStakedNfts,
}) => {
  const { ethereum, connected } = useEthereum()
  const { setNftStakings, nftStakings } = useGlobalApr()

  useEffect(() => {
    console.log("stakedNftID=", stakedNftId)
    setChosenNft(stakedNftId)
    setApprovedNft(!!stakedNftId)
    setShowDropdown(!stakedNftId ? true : false)
    setIsStaked(stakedNftId ? true : false)
  }, [stakedNftId])

  // useEffect(() => {
  //   const [chosenNft, setChosenNft] = setChosenNft(stakedNftId)
  //   const [approvedNft, setApprovedNft] = setApprovedNft(!!stakedNftId)
  //   const [showDropdown, setShowDropdown] = setShowDropdown(!stakedNftId ? true : false)
  //   const [isStaked, setIsStaked] = setIsStaked(stakedNftId ? true : false)
  // }, [stakedNftId])

  // useEffect(() => {
  //   getBalances(ethereum.signer, basePrice, rewardPrice)
  // }, [connected, basePrice, rewardPrice])
  // const [baseBalanceRaw, setBaseBalanceRaw] = useState(null)
  // const [baseBalance, setBaseBalance] = useState(0)
  // const [baseAmount, setBaseAmount] = useState(0)
  // const [baseStaked, setBaseStaked] = useState(0)
  // const [rewardEarned, setRewardEarned] = useState(0)
  // const [totalbaseStaked, setTotalbaseStaked] = useState(0)
  // const [rewardRate, setRewardRate] = useState(0)

  // const [apr, setApr] = useState(0)

  const [loading, setLoading] = useState(false)
  // const [approved, setApproved] = useState(false)
  const { openModal, closeModal } = useModal()

  const [nfts, setNFTs] = useState([])
  const [chosenNft, setChosenNft] = useState(stakedNftId)
  const [approvedNft, setApprovedNft] = useState(!!stakedNftId)
  const [showDropdown, setShowDropdown] = useState(!stakedNftId ? true : false)
  const [isStaked, setIsStaked] = useState(stakedNftId ? true : false)

  useEffect(() => {
    if (connected) {
      getNFTs(ethereum.address).then((nfts) => {
        console.log(nfts)
        setNFTs(nfts)
      })
    }
  }, [connected])

  const awaitTx = async (res, msg) => {
    openModal(
      <TransactionModal
        message={msg}
        details={`TxHash: ${res.hash}`}
        hash={res.hash}
      />
    )
    await res.wait()
    closeModal()
    return
  }

  const approveNft = async () => {
    if (!connected) return connectFirst()
    setLoading(true)

    try {
      const _stakingContract = stakingContract
      const _nftContract = nftContract
      const _stakingApproved = await _nftContract
        .connect(ethereum.signer)
        .getApproved(chosenNft)
      if (
        _stakingApproved.toLowerCase() != stakingContract.address.toLowerCase()
      ) {
        const resStaking = await _nftContract
          .connect(ethereum.signer)
          .approve(_stakingContract.address, chosenNft)

        await awaitTx(
          resStaking,
          "Approving DOE NFT for staking. Remember you must still stake NFT after this. please wait..."
        )
      }
      setApprovedNft(true)
    } catch (err) {
      handleTxError(err)
      setApprovedNft(false)
    }
    setLoading(false)
  }

  const handleTxError = (err) => {
    try {
      closeModal()
    } catch {
      console.log("nothing to close")
    }

    let msg = err.toString()
    if (err.message && err.code)
      msg = `code: ${err.code}\nmessage: ${err.message}`

    openModal(<ErrorModal message={"Transaction Failed!"} details={msg} />)
    console.error(err)
  }

  const connectFirst = () => {
    openModal(
      <ConnectWalletModal
        message={
          "You must connect your wallet before staking/unstaking. Set your wallet's network to Ethereum."
        }
      />
    )
  }

  const stakeNft = async () => {
    if (!connected) return connectFirst()
    setLoading(true)

    try {
      const gas = await stakingContract
        .connect(ethereum.signer)
        .estimateGas.stakeNFT(chosenNft)

      console.log(gas.toNumber())
      const gasLimit = Math.max(gas.toNumber() + 5000, 110159)

      const res = await stakingContract
        .connect(ethereum.signer)
        .stakeNFT(chosenNft, {
          gasLimit: gasLimit.toString(),
        })

      await awaitTx(res, `Staking DOE NFT, please wait...`)
      setShowDropdown(false)
      setIsStaked(true)
      setTimeout(() => getStakedNfts(), 1000)
      setNftStakings([...nftStakings.filter((v) => !!v), chosenNft])
    } catch (err) {
      handleTxError(err)
      setShowDropdown(true)
      setIsStaked(false)
    }
    setLoading(false)
  }

  const unstake = async () => {
    if (!connected) return connectFirst()
    setLoading(true)

    try {
      const gas = await stakingContract
        .connect(ethereum.signer)
        .estimateGas.unstakeNFT(chosenNft)
      console.log(gas.toNumber())
      const gasLimit = Math.max(gas.toNumber() + 5000, 100000)
      const res = await stakingContract
        .connect(ethereum.signer)
        .unstakeNFT(chosenNft, { gasLimit: gasLimit.toString() })

      await awaitTx(res, `Unstaking DOE NFT, please wait...`)
      setNftStakings(nftStakings.filter((id) => id != chosenNft))
      setIsStaked(false)
      setShowDropdown(true)
      setNftStakings([])
      setTimeout(() => getStakedNfts({ unstakeIds: [chosenNft] }), 1000)
    } catch (err) {
      handleTxError(err)
      setIsStaked(true)
      setShowDropdown(false)
    }
    setLoading(false)
  }

  const getBtnStyle = () => {
    if (loading) {
      return {
        opacity: 0.8,
      }
    } else {
      // !loading
      if (approvedNft && isStaked) {
        return {
          opacity: 0.8,
        }
      }
    }
    return {}
  }

  /**
   *
   * @returns {boolean} is button disabled or not
   */
  const getBtnStatus = () => {
    if (!chosenNft) {
      return true
    }

    if (loading) {
      return true
    } else {
      if (approvedNft && isStaked) {
        return true
      }
    }
    return false
  }

  const getOptions = () => {
    const collator = new Intl.Collator(undefined, {
      numeric: true,
      sensitivity: "base",
    })
    return nfts
      .map((nft) => ({
        value: nft.id,
        label: (
          <div
            key={nft.id}
            style={{
              display: "flex",
              alignItems: "center",
              columnGap: 10,
            }}
          >
            <img
              style={{ height: 70, width: 70 }}
              src={getNftUrl(nft.id)}
              alt={nft.id}
            />
            <span>{nft.id}</span>
          </div>
        ),
      }))
      .sort((a, b) => collator.compare(a.value, b.value))
  }

  return (
    <div className="col-md-6 col-sm-12 col-12">
      {/* <div className="stakig_card_top">
        <img src={baseSymbol} alt="icon" />
        <p>KDOE Staking</p>
        <span id="Tooltip">
          <img src={Info} alt="info" />
        </span>
      </div> */}
      <div className="stakig_card_bottom">
        {showDropdown ? (
          <Select
            placeholder="Choose NFT"
            options={getOptions()}
            onChange={async (e) => {
              console.log(e)
              setChosenNft(e.value)
              const _stakingContract = stakingContract
              const _nftContract = nftContract
              const _stakingApproved = await _nftContract
                .connect(ethereum.signer)
                .getApproved(e.value)
              if (
                _stakingApproved.toLowerCase() ==
                stakingContract.address.toLowerCase()
              ) {
                setApprovedNft(true)
              }
            }}
          />
        ) : null}
        {/* <div className="row">
          <div className="col-6">
            <span>APR</span>
            <p>{formatNumber(apr, 2)}%</p>
          </div>
          <div className="col-6">
            <span>Wallet Balance</span>
            <p>
              {formatNumber(baseBalance, 1)} {baseSymbolTxt}
            </p>
          </div>
          <div className="col-6">
            <span>Staked</span>
            <p>
              {formatNumber(baseStaked, 1)} {baseSymbolTxt}
            </p>
          </div>
          <div className="col-6">
            <span>Earned</span>
            <p>
              {formatNumber(rewardEarned, 4)} {rewardSymbolTxt}
            </p>
          </div>
        </div> */}
        <div className="name_amount_cards">
          {chosenNft ? (
            <div
              style={{ display: "flex", width: "100%", marginBottom: "1rem" }}
            >
              <img
                style={{
                  margin: "0 auto",
                  height: 150,
                  width: 150,
                }}
                src={getNftUrl(chosenNft) ?? null}
                alt={chosenNft}
              />
            </div>
          ) : null}
          {/* <label>Amount to Stake (max: {baseBalance})</label>
          <div className="mb-3">
            <input
              type="number"
              value={baseAmount}
              max={`${baseBalance}`}
              onChange={updateAmount}
              placeholder="Amount"
            />
            <span>KDOE</span>
          </div> */}
          {/* {stakingErrMsg ? (
            <small
              style={{
                color: "maroon",
                textAlign: "center",
                display: "block",
                padding: "4px",
                fontSize: 14,
                fontWeight: 500,
              }}
            >
              {stakingErrMsg}
            </small>
          ) : null} */}
          {connected ? (
            <>
              <Button
                onClick={unstake}
                disabled={!isStaked}
                className="blue_btn unstake h50 w-100 btn btn-secondary"
              >
                Unstake
              </Button>
            </>
          ) : (
            <Button onClick={connectFirst}>Connect Wallet</Button>
          )}
        </div>
      </div>
    </div>
  )
}

export default CustomNFTStakingCard
