import React from "react"
import "./Data.scss"

const Data = ({ style, label, children }) => {
  return (
    <div className="data" style={style}>
      <label className="heading_data_card" htmlFor="data">
        {label}
      </label>
      <div id="data">{children}</div>
    </div>
  )
}

export default Data
