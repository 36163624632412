import React from "react";
import { Link } from "react-router-dom";

import './footer.scss'
import ToS from '../../../assets/docs/ToS.pdf'
const Footer = () => {
  return ( 
    <div className="footer">
      <div className="container">
        <h3 className="common-heading">Contact us</h3>
        <div className="row emails-footer">
          <div className="col-lg-4 col-md-4 col-sm-4 col-12">
            <a href={"mailto:info@kudoe.io"}>info@kudoe.io</a>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-4 col-12">
            <a href={"mailto:marketing@kudoe.io"}>marketing@kudoe.io</a>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-4 col-12">
            <a href={"mailto:development@kudoe.io"}>development@kudoe.io</a>
          </div>
        </div>
        <div className="links-footer">
        <a href='https://coinmarketcap.com/currencies/kudoe/' target="_blank">CoinMarketCap</a>
          <a href="https://etherscan.io/token/0x5f190f9082878ca141f858c1c90b4c59fe2782c5" target="_blank">Etherscan</a>
          <a href='https://www.coingecko.com/en/coins/kudoe' target="_blank">CoinGecko</a>
          <a href="https://www.dextools.io/app/en/ether/pair-explorer/0xdd8f50f1059c50d7d75fe2f5d7d262964fb2ae98" target="_blank">DexTools</a>
          <a href="/static/media/ToS.361b924042c4734848f5.pdf"target="_blank">Terms of Service</a>
        </div>
        <p className="copyright">Cryptoasset Disclaimer: Trading/Minting crypto-assets carries a high level of risk, and may not be suitable for all investors. The possibility exists that you could sustain a loss of some or all of your initial investment and therefore you should not invest money that you cannot afford to lose.</p>
      </div>
    </div>
   );
}
 
export default Footer;