import React from "react";
// css
import './ctzn.scss';
import Gleam from "../../components/common/Gleam/gleam";

const CTZN = () => {
  return ( 
    <div>
      <div className="buy-outer">
        <div className="container">
        <div className="row justify-content-center">
            <div className="col-xxl-5 col-xl-6 col-lg-7 col-md-8 col-sm-12 col-12">
            <Gleam/>
          </div>
          </div>
        </div>
      </div>
    </div>
   );  
}
 
export default CTZN;