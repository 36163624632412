import React from "react"

const ErrorModal = ({ message, details }) => {
  return (
    <>
      <h3 className="modal_heading">
        <i className="fas fa-exclamation-circle"></i>
        Whoops!
      </h3>
      <p className="modal_p white_text">{message}</p>
      {details ? (
        <>
          <pre className="modal_p white_text">{details}</pre>
          <i className="modal_p white_text">
            These details might help us debug the error.
          </i>
        </>
      ) : null}
    </>
  )
}

export default ErrorModal
