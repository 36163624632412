import BlogImage from '../../assets/images/home/news/3.webp'
import InlineImage1 from '../../assets/images/home/news/news3details/1.webp'
import InlineImage2 from '../../assets/images/home/news/news3details/2.png'
import './news.scss'

function NewsDetails() {
  return ( 
    <div className="container">
      <div className="row">
        <div className="col-12">
          <div className="big-blog-img mb-lg-5 mb-4">
            <img src={BlogImage} alt="blog" className="img-fluid"/>            
          </div>
          <h4 className='mt-3'>Introducing Kudoe™</h4>
          <p>Introducing Kudoe™, the exciting new brand that builds on the success of Dogs of Elon. We are proud to unveil our new branding and share our plans for rapid growth and expansion of our community. With Kudoe, we’re taking blockchain and web3 to the next level by creating a more accessible and fun platform for everyone.</p>
          <h4 className='mt-3'>The Name</h4>
          <p>The process of creating the new branding for Kudoe was an exciting and collaborative effort. We wanted to build on the success of Dogs of Elon and create a new brand that would be more inclusive and appealing to a wider range of crypto holders.</p>
          <p>One of the key considerations in the branding process was the name. We were inspired by the original name of Dogs of Elon and wanted to maintain the connection to it, while at the same time creating a new name that would be more broad and appealing. After much discussion and brainstorming, we have decided on Kudoe as our new brand name. We believe that it embodies the spirit of our company and will help us to continue to grow and thrive.</p>
          <p><i>Deriving from Greek, kudos entered English as slang popular at British universities in the 19th century. In its earliest use, the word referred to the prestige or renown that one gained by having accomplished something noteworthy. <a href="https://www.merriam-webster.com/dictionary/kudos" target="_blank">source</a></i></p>
          <p>Kudoe, was an exercise of combining the word ‘Kudos’ and its meaning of celebration and recognition of what we hope to accomplish for the future with the acronym ‘DOE’ that represents our past and the roots of where we came from.</p>
          <img src={InlineImage1} alt="blog" className="img-fluid"/>
          <h4 className='mt-3'>The Branding</h4>
          <p>The process of choosing the logo for Kudoe was extensive and involved the input of many people. We wanted to create a logo that was unique, memorable, and representative of our brand. To help us achieve this, we recruited one of the best design companies in the industry led by <a href="https://dribbble.com/lepisov/about" target="_blank">Dmitry Lepisov</a>.</p>
          <p>Dmitry Lepisov is a highly respected designer who has worked on many high-profile branding projects, including the <a href="https://dribbble.com/lepisov/projects/1680654-Immutable" target="_blank">Immutable</a> branding. He has a wealth of experience and expertise, and we knew that he would be the perfect person to help us create the perfect logo for Kudoe.</p>
          <p>Dmitry worked with us from start to finish, conducting research on the style, color, typography, and other aspects of our branding. He listened to our ideas and provided valuable insights and suggestions. In the end, we were able to create a logo that we are proud of and that perfectly reflects our brand.</p>
          <p>We are grateful to Dmitry Lepisov and his team for their hard work and expertise in helping us create the perfect logo for Kudoe. We are confident that our new logo will be a key part of our branding and will help us to stand out in the crowded world of blockchain and web3. You can view the design process on Dmitry’s <a href="https://dribbble.com/shots/19389781-Kudoe-Logo-Design" target="_blank">dribbble</a> showcase.</p>
          <img src={InlineImage2} alt="blog" className="img-fluid"/>
          <h4 className='mt-3'>Next Steps</h4>
          <p>We are excited to announce that Kudoe token will be launching on January 18th, 2023. This launch marks a major milestone for our project, and we are looking forward to sharing our new whitepaper, roadmap, vision, and mission with the world.</p>
          <p>Our goal with the launch is to rapidly grow our community and develop long-lasting relationships and partnerships. We believe that Kudoe has the potential to be a major player in the world of blockchain and web3, and we are committed to building a strong and vibrant community.</p>
          <p>Over the next couple of weeks, we will be rolling out more information about the launch. This will include details about the new whitepaper, roadmap, and other key aspects of our project. We encourage everyone to stay tuned and to follow us on social media for the latest updates.</p>
        </div>
      </div>
    </div>
   );
}

export default NewsDetails;