import React from "react";
import Card from 'react-bootstrap/Card';
import { Link } from "react-router-dom";
import BlueArrow from '../../assets/images/btn-arrow-blue.svg'
import { Fade } from "react-awesome-reveal";
const ExplorerCard = (props) => {
  if (props.link.startsWith("http")) {
    return ( 
      <Fade triggerOnce={true} duration={`2000`}>
        <Card className="explorecard">
          <Card.Img variant="top" src={props.cardimage} />
          <Card.Body>
            <Card.Title>{props.title}<a href={props.link} target="_blank"><img src={BlueArrow} alt="arrow"/></a></Card.Title>
            <Card.Text>
              {props.text}
            </Card.Text>        
          </Card.Body>
        </Card>
      </Fade>
     );
  } else {
    return ( 
      <Fade triggerOnce={true} duration={`2000`}>
        <Card className="explorecard">
          <Card.Img variant="top" src={props.cardimage} />
          <Card.Body>
            <Card.Title>{props.title}<Link to={props.link}><img src={BlueArrow} alt="arrow"/></Link></Card.Title>
            <Card.Text>
              {props.text}
            </Card.Text>        
          </Card.Body>
        </Card>
      </Fade>
     );
  }
}
 
export default ExplorerCard;