import React from "react";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import ProgressCard from "./progressCard";

const GovernanceTab = () => {
  return (
    <Tabs
      defaultActiveKey="inprogress" 
      className="governancetab"  
      justify         
    >
      {/* In progress tab panal */}
      <Tab eventKey="inprogress" title="In progress">
        <ProgressCard
          number="111"
          detail="Lorem ipsum dolor sit amet"
          btnclass="inprogress"
          link="/governance-detail"
        />
        <ProgressCard
          number="112"
          detail="Lorem ipsum dolor sit amet"
          btnclass="inprogress"
          link="/governance-detail"
        />
        <ProgressCard
          number="113"
          detail="Lorem ipsum dolor sit amet"
          btnclass="inprogress"
          link="/governance-detail"
        />
        <ProgressCard
          number="114"
          detail="Lorem ipsum dolor sit amet"
          btnclass="inprogress"
          link="/governance-detail"
        />
        <ProgressCard
          number="115"
          detail="Lorem ipsum dolor sit amet"
          btnclass="inprogress"
          link="/governance-detail"
        />        
      </Tab>
      {/* Executed tab panal  */}
      <Tab eventKey="executed" title="Executed">
        <ProgressCard
          number="111"
          detail="Lorem ipsum dolor sit amet"
          btnclass="inprogress"
          link="/governance-detail"
        />
        <ProgressCard
          number="112"
          detail="Lorem ipsum dolor sit amet"
          btnclass="inprogress"
          link="/governance-detail"
        />
        <ProgressCard
          number="113"
          detail="Lorem ipsum dolor sit amet"
          btnclass="inprogress"
          link="/governance-detail"
        />
        <ProgressCard
          number="114"
          detail="Lorem ipsum dolor sit amet"
          btnclass="inprogress"
          link="/governance-detail"
        />
        <ProgressCard
          number="115"
          detail="Lorem ipsum dolor sit amet"
          btnclass="inprogress"
          link="/governance-detail"
        />
      </Tab>
      {/* Failed tab panal  */}
      <Tab eventKey="failed" title="Failed">
        <ProgressCard
          number="111"
          detail="Lorem ipsum dolor sit amet"
          btnclass="inprogress"
          link="/governance-detail"
        />
        <ProgressCard
          number="112"
          detail="Lorem ipsum dolor sit amet"
          btnclass="inprogress"
          link="/governance-detail"
        />
        <ProgressCard
          number="113"
          detail="Lorem ipsum dolor sit amet"
          btnclass="inprogress"
          link="/governance-detail"
        />
        <ProgressCard
          number="114"
          detail="Lorem ipsum dolor sit amet"
          btnclass="inprogress"
          link="/governance-detail"
        />
        <ProgressCard
          number="115"
          detail="Lorem ipsum dolor sit amet"
          btnclass="inprogress"
          link="/governance-detail"
        />
      </Tab>
    </Tabs>
  );
}
 
export default GovernanceTab;