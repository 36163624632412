export const shortAddress = (a) => {
  if (!a) {
    return "0"
  }
  if (a.length < 10) return a
  return `${a.slice(0, 6)}...${a.slice(-4)}`
}

export const formatNumber = (a, fractionalDigits) => {
  const num = parseFloat(a.toString())
  if (fractionalDigits < 1) return `${num}`
  return num.toFixed(fractionalDigits || 6)
}

export const formatNumbersWithCommas = (x) => {
  // let parts = x.toString().split(".")
  // const int = parts[0].toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
  // if (parts.length > 1)
  //     return `${int}.${parts}`
  // return `${int}`

  // const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  // if (isSafari) return x.toString()

  // const rx = new RegExp('/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g')

  // let parts = x.toString().split(".")
  // const int = parts[0].toString().replace(rx, ",")
  // if (parts.length > 1)
  //     return `${int}.${parts}`
  // return `${int}`

  return parseFloat(x.toString()).toLocaleString("en-US", {
    maximumFractionDigits: 2,
  })
}
