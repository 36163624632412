import React from "react";
import { Link } from "react-router-dom";
import ProgressBar from 'react-bootstrap/ProgressBar';
// images 
import Back from '../../assets/images/about/back.svg'


// css
import './governancedetails.scss'
import { Button } from "reactstrap";
const GovernanceDetails = () => {
  return ( 
    <div>
      <div className="governance-detail">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-10 col-md-12">
              <div className="row">
                <div className="col-12 mb-4">
                  <Link className="back-link" to="/governance"><img src={Back} alt="back"/>Governance</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="g-d-bluesection">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-10 col-md-12">
                <div className="row">
                  <div className="col-xxl-6 col-lg-7 col-md-9 col-sm-9 col-12">
                    <h4>Lorem ipsum dolor sit amet, consectetur adipiscing? </h4>
                  </div>
                  <div className="col-xxl-6 col-lg-5 col-md-3 col-sm-3 col-12 text-sm-end text-left">
                    <span className="inprogress">In progress</span>
                  </div>
                  <div className="col-12 mt-4">
                    <div className="ending-voting">
                      <p><span>Voting ending</span> Feb. 19, 2021, 4:41 AM EST </p>
                      <p><span>Proposer:</span> 0xa626EB9c...21443</p>
                    </div>
                  </div>
                </div>
                <div className="row justify-content-between">
                  <div className="col-lg-5 col-md-6 col-sm-12">
                    <div className="progress-outer">
                      <div className="progress-text">
                        <h5>For</h5>
                        <h3>72,126,216</h3>
                      </div>
                      <ProgressBar className="green" now={70} />
                    </div>
                  </div>
                  <div className="col-lg-5 col-md-6 col-sm-12">
                    <div className="progress-outer">
                      <div className="progress-text">
                        <h5>Against</h5>
                        <h3>2,246,152</h3>
                      </div>
                      <ProgressBar className="red" now={30} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="g-d-detailtext">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-10 col-md-12">
                <h3>Details</h3>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Praesent tristique magna sit amet. In mollis nunc sed id. Dolor sit amet consectetur adipiscing. Dignissim convallis aenean et tortor at risus viverra. Massa sapien faucibus et molestie ac.</p>
                <p>Met commodo nulla facilisi nullam vehicula ipsum a arcu cursus. Consectetur adipiscing elit pellentesque habitant morbi tristique senectus. </p>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Praesent tristique magna sit amet. In mollis nunc sed id. Dolor sit amet consectetur adipiscing. Dignissim convallis aenean et tortor at risus viverra. Massa sapien faucibus et molestie ac. </p>
                <div className="btns-approve-cancel">
                  <Button>Approve</Button>
                  <Button>Reject</Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
   );
}
 
export default GovernanceDetails;