import React, { useEffect } from "react"
import { pushReferrals } from "../../helpers/referrals"
import "./TransactionModal.scss"

const TransactionModal = ({ message, details, hash }) => {
  useEffect(() => {
    try {
      pushReferrals(hash)
    } catch (err) {
      console.error(err)
    }
  }, [])
  return (
    <>
      <h3 className="modal_heading">
        <i className="fas fa-glass-cheers"></i>
        Transaction Sent
      </h3>
      <p className="modal_p white_text">{message}</p>

      {details ? <pre className="modal_p white_text">{details}</pre> : null}
    </>
  )
}

TransactionModal.defaultProps = {
  hash: "",
}

export default TransactionModal
