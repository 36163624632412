import React from "react";
import { Button } from "reactstrap";

// image 
import StakingImage from '../../assets/images/staking/staking.png'
const StakingPopupCard = () => {
  return ( 
    <div className="staking-popup-card">
      <div className="row">
        <div className="col-md-5 col-sm-12 col-12">
          <img src={StakingImage} alt="staking" className="img-fluid"/>
        </div>
        <div className="col-md-7 col-sm-12 col-12">
          <div className="row">
            <div className="col-12">
              <h5>StakingImage</h5>
            </div>
            <div className="col-6">
              <span>Price locked</span>
              <p>70.91%</p>
            </div>
            <div className="col-6">
              <span>Total Supply</span>
              <p>81/81 units</p>
            </div>
            <div className="col-6">
              <span>Rarity</span>
              <p>Commom</p>
            </div>
            <div className="col-6">
              <span>Multiplier</span>
              <p>1,05 x</p>
            </div>
            <div className="col-12">
              <Button className="blue_btn h50 w-100 mb-3">Stake</Button>
              <span>Units owned: 0</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    
   );
}
 
export default StakingPopupCard;