import React, { Component } from "react";
import Select, { components } from "react-select";
import './coinSelectCard.scss';

const options = [
  { value: "ETH", label: "ETH", amount: "1000000", icon:"eth-icon.png"},
  { value: "DOE", label: "DOE", amount: "5", icon:"dog-icon.png"},  
];

const { Option,SingleValue } = components;
const IconOption = props => (
  <Option {...props}>
    <img
      src={require('../../../assets/images/' + props.data.icon)}      
      style={{ width: 30 }}
      alt={props.data.label}
    />
    {props.data.label}
  </Option>
);
const ValueOption = (props) => ( 
  <SingleValue {...props}> 
  <div className="coin_card_select_icon">    
    <img
      src={require('../../../assets/images/' + props.data.icon)}      
      style={{ width: 36 }}
      alt={props.data.label}
      />
      <div>
        <span className="coin_name">{props.data.label}</span>
        <span className="amount_balance">Bal. {props.data.amount}</span>
      </div>    
  </div>
  </SingleValue> 
);


class CoinSelectCard extends Component {
  state = {  } 
  render() { 
    return (
      <div className='coin_card'>
        <div className='left_side_card'>
          <div>                 
            <input type="number" placeholder="Enter Amount"/>          
          </div>
        </div>
        <div className='right_side_card'>          
          <Select  
            className="drop_down_coin_card"                
            classNamePrefix="coin_card_select"                
            defaultValue={options[0]}
            options={options}
            isSearchable={false}                              
            components={{ Option: IconOption,SingleValue: ValueOption}}                  
          />
        </div>
      </div>
    );
  }
}
 
export default CoinSelectCard;

