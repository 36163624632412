import create from "zustand"

export const useModal = create((set) => ({
  modalJsx: <></>,
  showModal: false,
  openModal: (jsx) => set({ showModal: true, modalJsx: jsx }),
  closeModal: (funcRef) => {
    document.body.style.overflow = "auto"
    if (funcRef) {
      document.removeEventListener("click", funcRef)
    }
    set({ showModal: false, modalJsx: <></> })
  },
}))
