import React, { useState } from "react"
import {
  Collapse,
  Navbar,
  NavbarToggler,
  Nav,
  NavItem,
} from "reactstrap"
import { Link, useLocation } from "react-router-dom"

// css
import "./header.scss"
// images
import Logo from "../../../assets/images/logo/logo.png"
import ConnectWallet from "../../ConnectWallet"
const Header = () => {
  const [isOpen, setIsOpen] = useState(false)
  const toggle = () => setIsOpen(!isOpen)
  let location = useLocation()
  React.useEffect(() => {
    setIsOpen(false)
  }, [location])
  return (
    <div className="my_nav">
      <div className="container">
        <Navbar color="faded" light expand="lg">
          <Link className="navbar-brand" to="/">
            <img src={Logo} alt="log" />
          </Link>
          <NavbarToggler onClick={toggle} className="me-2" />
          <Collapse isOpen={isOpen} navbar>
            <Nav className="me-auto" navbar>
              <NavItem>
                <Link className="nav-link" to="/#Gaming">
                  Gaming
                </Link>
              </NavItem>
              <NavItem>
                <a className="nav-link" href="https://opensea.io/collection/dogs-of-elon" target="_blank" >
                  NFT
                </a>
              </NavItem>
              <NavItem>
                <a className="nav-link" href="https://governance.kudoe.io/#/" target="_blank" >
                  Governance
                </a>
              </NavItem>
             <NavItem>
                <Link className="nav-link" to="/staking">
                  Staking
                </Link>
              </NavItem>
              <NavItem>
                <Link className="nav-link" to="/#Community">
                  Community
                </Link>
              </NavItem>
              <NavItem>
                <Link className="nav-link" to="/aboutus">
                  About
                </Link>
              </NavItem>
              <NavItem>
                <a className="nav-link" href="https://marketplace.kudoe.io" target="_blank" >
                  Marketplace
                </a>
              </NavItem>
            </Nav>
            <Nav navbar className="align-items-lg-center">
              <NavItem>
                <a className="nav-link" href="https://kudoe-tm.gitbook.io/kudoe-tm/" target="_blank">Documentation</a>
              </NavItem>
              <ConnectWallet
                className="blue_btn h50 plr-30 btn btn-secondary"

              >
                Connect Wallet
              </ConnectWallet>
            </Nav>
          </Collapse>
        </Navbar>
      </div>
    </div>
  )
}

export default Header
