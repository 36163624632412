import React from "react";
import { Link } from "react-router-dom";

const ProgressCard = (props) => {
  return ( 
    <Link to={props.link}>
      <div className="progress-card">
        <span className="number">{props.number}</span>
        <p>{props.detail}</p>
        <span className={props.btnclass}>In progress</span>
      </div>
    </Link>
   );
}
 
export default ProgressCard;