import { useState, useEffect } from "react"
import { useEthereum } from "../hooks/useEthereum"
import { Button } from "reactstrap"

import { formatEther, parseEther } from "@ethersproject/units"
import Info from "../assets/images/staking/info-icon.svg"

import { formatNumber } from "../helpers/string"
import { useModal } from "../hooks/useModal"
import ConnectModal from "./modal/ConnectModal"
import TransactionModal from "./modal/TransactionModal"
import ErrorModal from "./modal/ErrorModal"
import StakeModal from "./modal/StakeModal"
import ConnectWalletModal from "./modal/ConnectWalletModal"
import { useGlobalApr } from "../store/globalApr"

const CustomStakingCard = ({
  baseToken,
  baseSymbolTxt,
  rewardSymbolTxt,
  rewardToken,
  stakingContract,
  governanceContract,
  baseSymbol,
  rewardSymbol,
  basePrice,
  rewardPrice,
  boostByPercentage,
}) => {
  const { ethereum, connected } = useEthereum()
  useEffect(() => {
    getBalances(ethereum.signer, basePrice, rewardPrice)
  }, [connected, basePrice, rewardPrice])
  const [baseBalanceRaw, setBaseBalanceRaw] = useState(null)
  const [baseBalance, setBaseBalance] = useState(0)
  const [baseAmount, setBaseAmount] = useState(0)
  const [baseStaked, setBaseStaked] = useState(0)
  const [rewardEarned, setRewardEarned] = useState(0)
  const [totalbaseStaked, setTotalbaseStaked] = useState(0)
  const [rewardRate, setRewardRate] = useState(0)
  const [stakingErrMsg, setStakingErrMsg] = useState("")

  const [apr, setApr] = useState(0)

  const [loading, setLoading] = useState(false)
  const [approved, setApproved] = useState(false)
  const { openModal, closeModal } = useModal()

  const { setGlobalApr } = useGlobalApr()

  useEffect(() => {
    const _aprVal =
      (86400 * rewardRate * 365 * 100 * rewardPrice) /
      (totalbaseStaked * basePrice)

    setApr(_aprVal)
    setGlobalApr(_aprVal)
  }, [apr, boostByPercentage])

  useEffect(() => {
    setStakingErrMsg("")
    if (approved && baseAmount <= 0) {
      setStakingErrMsg("Staking amount should be greater than 0")
    }
  }, [baseAmount, approved])

  const delay = (sec) => {
    return new Promise((resolve) => {
      setTimeout(resolve, 1000 * sec)
    })
  }

  const getBalances = async (s, basePrice, rewardPrice) => {
    console.log("baseStaked")
    if (s == null) return
    if (basePrice === 0 || rewardPrice === 0) {
      console.log("prices", basePrice, rewardPrice)
      return
    }

    console.log("fetching info.")

    try {
      const _baseBalanceRaw = await baseToken
        .connect(ethereum.signer)
        .balanceOf(ethereum.address)
      setBaseBalanceRaw(_baseBalanceRaw)
      setBaseBalance(parseFloat(formatEther(_baseBalanceRaw)))
    } catch (err) {
      setBaseBalance(0)
    }

    await delay(0.1)

    try {
      const _baseStaked = await stakingContract
        .connect(ethereum.signer)
        .balanceOf(ethereum.address)
      setBaseStaked(Math.floor(parseFloat(formatEther(_baseStaked))*100)/100)
    } catch (err) {
      setBaseStaked(0)
    }

    await delay(0.1)

    try {
      const _rewardEarned = await stakingContract
        .connect(ethereum.signer)
        .earned(ethereum.address)
      setRewardEarned(parseFloat(formatEther(_rewardEarned)))
    } catch (err) {
      setRewardEarned(0)
    }

    await delay(0.1)

    try {
      let _gKDOEapproved
      let _governanceapproved
      const _approved = await baseToken
        .connect(ethereum.signer)
        .allowance(ethereum.address, stakingContract.address)
      const amountApproved = parseFloat(formatEther(_approved))
      let __approved = amountApproved >= baseBalance
      if (__approved) {
        _gKDOEapproved = await governanceContract
          .connect(ethereum.signer)
          .allowance(ethereum.address, stakingContract.address)
        const amountgKDOEApproved = parseFloat(formatEther(_gKDOEapproved))
        __approved = amountgKDOEApproved >= baseBalance
        if (__approved) {
          _governanceapproved = await baseToken
            .connect(ethereum.signer)
            .allowance(ethereum.address, governanceContract.address)
          const amountGovernanceApproved = parseFloat(
            formatEther(_governanceapproved)
          )
          __approved = amountGovernanceApproved >= baseBalance
        }
      }
      setApproved(__approved)
      if (_approved.isZero()) setApproved(false)
      if (_gKDOEapproved.isZero()) setApproved(false)
      if (_governanceapproved.isZero()) setApproved(false)
      console.log("Approved KDOE", _approved.toString())
      console.log("Approved GKDOE", _gKDOEapproved.toString())
      console.log("Approved Governance", _governanceapproved.toString())
    } catch (err) {
      console.error(err)
      setBaseBalance(0)
    }

    await delay(0.1)

    try {
      const _totalStaked = await stakingContract
        .connect(ethereum.signer)
        .totalSupply()
      const _rewardRate = await stakingContract
        .connect(ethereum.signer)
        .rewardRate()
      console.log("reward rate " + basePrice)
      setRewardRate(parseFloat(formatEther(_rewardRate)))
      setTotalbaseStaked(parseFloat(formatEther(_totalStaked)))
      setApr(
        (86400 * _rewardRate * 365 * 100 * rewardPrice) /
          (_totalStaked * basePrice)
      )
    } catch (err) {
      setRewardRate(0)
      setTotalbaseStaked(0)
    }

    setBaseAmount(Math.floor(baseBalance))
  }
  const updateAmount = (e) => {
    if (e.target) {
      const _v = e.target.value
      if (_v < 0) {
        setBaseAmount(0)
        e.target.value = baseAmount
        return false
      }

      if (_v > baseBalance) {
        setBaseAmount(baseBalance)
        e.target.value = baseAmount
        return true
      }

      setBaseAmount(_v)
      return
    }
  }

  const awaitTx = async (res, msg) => {
    openModal(
      <TransactionModal
        message={msg}
        details={`TxHash: ${res.hash}`}
        hash={res.hash}
      />
    )
    await res.wait()
    closeModal()
    return
  }

  const approve = async () => {
    if (!connected) return connectFirst()
    setLoading(true)

    try {
      const _stakingContract = stakingContract
      const _stakingApproved = await baseToken
        .connect(ethereum.signer)
        .allowance(ethereum.address, _stakingContract.address)
      const amountStakingApproved = parseFloat(formatEther(_stakingApproved))
      if (amountStakingApproved < baseBalance) {
        const resStaking = await baseToken
          .connect(ethereum.signer)
          .approve(_stakingContract.address, parseEther("1000000000000000000"))

        await awaitTx(
          resStaking,
          "Approving $KDOE for staking. Remember you must still approve $KDOE for governance after this. please wait..."
        )
      }

      const _governanceContract = governanceContract
      const _governanceapproved = await baseToken
        .connect(ethereum.signer)
        .allowance(ethereum.address, _governanceContract.address)
      const amountGovernanceApproved = parseFloat(
        formatEther(_governanceapproved)
      )
      if (amountGovernanceApproved < baseBalance) {
        const resGovernance = await baseToken
          .connect(ethereum.signer)
          .approve(
            _governanceContract.address,
            parseEther("1000000000000000000")
          )

        await awaitTx(
          resGovernance,
          "Approving $KDOE for Governance. Remember you must still approve $gKDOE after this. please wait..."
        )
      }

      const _gKDOEapproved = await governanceContract
        .connect(ethereum.signer)
        .allowance(ethereum.address, _stakingContract.address)
      const amountgKDOEApproved = parseFloat(formatEther(_gKDOEapproved))
      if (amountgKDOEApproved < baseBalance) {
        const res = await governanceContract
          .connect(ethereum.signer)
          .approve(_stakingContract.address, parseEther("1000000000000000000"))

        await awaitTx(
          res,
          "Approving $gKDOE for staking. Remember you must still stake after this. please wait..."
        )
      }

      getBalances(ethereum.signer, basePrice, rewardPrice)
    } catch (err) {
      handleTxError(err)
    }

    setLoading(false)
  }

  const handleTxError = (err) => {
    try {
      closeModal()
    } catch {
      console.log("nothing to close")
    }

    let msg = err.toString()
    if (err.message && err.code)
      msg = `code: ${err.code}\nmessage: ${err.message}`

    openModal(<ErrorModal message={"Transaction Failed!"} details={msg} />)
    console.error(err)
  }

  const connectFirst = () => {
    openModal(
      <ConnectWalletModal
        message={
          "You must connect your wallet before claiming. Set your wallet's network to Ethereum."
        }
      />
    )
  }

  const stake = async () => {
    if (!connected) return connectFirst()
    setLoading(true)

    try {
      let _value = parseEther(baseAmount.toString())
      _value = _value.gt(baseBalanceRaw) ? baseBalanceRaw : _value
      console.log("test value " + _value)
      const gas = await stakingContract
        .connect(ethereum.signer)
        .estimateGas.stakeForgKDOE(_value)

      console.log(gas.toNumber())
      const gasLimit = Math.max(gas.toNumber() + 5000, 110159)

      const res = await stakingContract
        .connect(ethereum.signer)
        .stakeForgKDOE(_value, {
          gasLimit: gasLimit.toString(),
        })

      await awaitTx(res, `Staking ${baseAmount} $KDOE, please wait...`)
      setTimeout(
        () => getBalances(ethereum.signer, basePrice, rewardPrice),
        1000
      )
    } catch (err) {
      handleTxError(err)
    }
    setLoading(false)
  }

  const claimAndExit = async () => {
    if (!connected) return connectFirst()
    setLoading(true)

    try {
      let _value = parseEther(baseStaked.toString())

      console.log(_value)
      const gas = await stakingContract
        .connect(ethereum.signer)
        .estimateGas.withdrawForgKDOE(_value)
      console.log(gas.toNumber())
      const gasLimit = Math.max(gas.toNumber() + 5000, 100000)
      const res = await stakingContract
        .connect(ethereum.signer)
        .withdrawForgKDOE(_value, { gasLimit: gasLimit.toString() })

      await awaitTx(res, `Exiting $KDOE staking pool, please wait...`)
      getBalances(ethereum.signer, basePrice, rewardPrice)
    } catch (err) {
      handleTxError(err)
    }
    setLoading(false)
  }

  const claimRewards = async () => {
    if (!connected) return connectFirst()
    setLoading(true)
    console.log(ethereum.signer)
    try {
      const gas = await stakingContract
        .connect(ethereum.signer)
        .estimateGas.getReward()
      const gasLimit = Math.max(gas.toNumber() + 5000, 100000)
      const res = await stakingContract
        .connect(ethereum.signer)
        .getReward({ gasLimit: gasLimit.toString() })

      await awaitTx(
        res,
        `Claiming $KDOE rewards from staking pool, please wait...`
      )
      getBalances(ethereum.signer, basePrice, rewardPrice)
    } catch (err) {
      console.log(err)
      handleTxError(err)
    }

    setLoading(false)
  }

  const getBtnStyle = () => {
    if (loading) {
      return {
        opacity: 0.8,
      }
    } else {
      // !loading
      if (approved && baseAmount <= 0) {
        return {
          opacity: 0.8,
        }
      }
    }
    return {}
  }

  /**
   *
   * @returns {boolean} is button disabled or not
   */
  const getBtnStatus = () => {
    if (loading) {
      return true
    } else {
      if (approved && baseAmount <= 0) {
        return true
      }
    }
    return false
  }

  return (
    <div className="col-md-6 col-sm-12 col-12">
      <div className="stakig_card_top">
        <img src={baseSymbol} alt="icon" />
        <p>KDOE Unstaking</p>
        <span id="Tooltip">
          <img src={Info} alt="info" />
        </span>
      </div>
      <div className="stakig_card_bottom">
        <div className="row">
          <div className="col-6">
            <span>Wallet Balance</span>
            <p>
              {formatNumber(baseBalance, 1)} {baseSymbolTxt}
            </p>
          </div>
          <div className="col-6"/>
          <div className="col-6">
            <span>Staked</span>
            <p>
              {formatNumber(baseStaked, 1)} {baseSymbolTxt}
            </p>
          </div>
          <div className="col-6">
            <span>Earned</span>
            <p>
              {formatNumber(rewardEarned, 4)} {rewardSymbolTxt}
            </p>
          </div>
        </div>
        <div className="name_amount_cards">
          {connected ? (
            <>
              <Button
                onClick={claimAndExit}
                className="blue_btn unstake h50 w-100 btn btn-secondary"
              >
                Claim Staked KDOE
              </Button>
              <Button
                onClick={claimRewards}
                className="blue_btn unstake h50 w-100 btn btn-secondary"
              >
                Claim Earned Rewards
              </Button>
            </>
          ) : (
            <Button onClick={connectFirst}>Connect Wallet</Button>
          )}
        </div>
      </div>
    </div>
  )
}

export default CustomStakingCard
