import React from 'react';
import HeadingTextBtn from '../../components/common/headinTextBtn/headingTextBtn';
import useEmblaCarousel from 'embla-carousel-react'
// images
import Mission from '../../assets/images/about/mission.png'
import Vision from '../../assets/images/about/vision.png'
import Slide from '../../assets/images/about/slide.png'
import MissionMobile from '../../assets/images/about/mission-m.png'
import VisionMobile from '../../assets/images/about/vision-m.png'
import Twitter from '../../assets/images/home/social-1.svg'
import Telegram from '../../assets/images/home/social-2.svg'
import Danny from '../../assets/images/about/team/danny.jpg'
import Prash from '../../assets/images/about/team/prash.jpg'
import CrypDOEbags from '../../assets/images/about/team/phil.jpg'
import Brian from '../../assets/images/about/team/brian.jpg'
import CP from '../../assets/images/about/team/cp.jpg'
import LFG from '../../assets/images/about/team/LFG.jpg'
// css 
import './aboutus.scss';
import { Link } from 'react-router-dom';
// pdf
import WP from '../../assets/docs/Kudoe_Whitepaper.pdf'

const AboutUs = () => {
  const [emblaRef] = useEmblaCarousel()
  return ( 
    <div>
      <div className='about-banner'>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-xl-8 xol-lg-10 col-md-12'>
              <HeadingTextBtn
                heading="THE ROAD FORWARD - KUDOE'S PATH TO SUCCESS"
                subtext="Immerse yourself in KDOE's Tokenomics and in-depth roadmap and vision."
                btnclass="blue_btn h60 arrow"
                btnlink={WP}
                btntext="Whitepaper" />
            </div>
          </div>
        </div>
      </div>
      {/* second section image only*/}
      <div className='about-image-section'>
      </div>
      {/* mission section */}
      <div className='mission'>
        <div className='container'>
          <div className='row align-items-center'>
            <div className='col-lg-5 col-md-5 col-sm-5 col-12 order-sm-2'>
              <img src={Mission} alt="Mission" className='img-fluid d-sm-block d-none' />
              <img src={MissionMobile} alt="Mission" className='img-fluid d-sm-none d-block' />

            </div>
            <div className='col-lg-7 col-md-7 col-sm-7 col-12'>
              <div className='row justify-content-center'>
                <div className='col-lg-9'>
                  <h4><span>Our Mission</span> at Kudoe is to utilize Web3 technology to create entertaining and highly engaging applications that push the boundaries of traditional gaming and the metaverse. </h4>
                  <p>We believe that by harnessing the power of blockchain technology, we can address the failures of web2 centralization in traditional gaming, finance, and governance, and create a new era of decentralized, transparent, and secure applications.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* vision section */}
      <div className='vision_outer'>
        <div className='mission'>
          <div className='container'>
            <div className='row align-items-center'>
              <div className='col-lg-5 col-md-5 col-sm-5 col-12'>
                <img src={Vision} alt="Mission" className='img-fluid d-sm-block d-none' />
                <img src={VisionMobile} alt="Mission" className='img-fluid d-sm-none d-block' />
              </div>
              <div className='col-lg-7 col-md-7 col-sm-7 col-12'>
                <div className='row justify-content-center'>
                  <div className='col-lg-10'>
                    <h4><span>Our Vision</span> is to create a vibrant and engaging blockchain gaming community where players can experience the excitement and thrill of decentralized gaming, while also enjoying the benefits of true ownership and control over their assets.</h4>
                    <p>Through our innovative use of blockchain technology and our commitment to delivering high-quality gaming experiences, we strive to deliver unique blockchain gaming that is both fun and rewarding for players of all levels.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* team section  */}
      <div className='team-section pb-lg-5 pb-3'>
        <div className='container'>
          <div className='row'>
            <div className='col-12'>
              <h2 className="common-heading text-center pb-lg-4 pb-2">Our Team</h2>
            </div>
            <div className='col-lg-3 col-md-4 col-sm-6 col-12'>
              <div className="card">
                <div className='profile_img'>
                  <img src={Danny} alt="profile" />
                </div>
                <h3>Danny</h3>
                <p>Operations</p>
                <div className='social_icon'>
                  <a href='https://twitter.com/JustDanny22' target="_blank"><img src={Twitter} alt="profile" /></a>
                  <a href='https://t.me/JustDanny21' target="_blank"><img src={Telegram} alt="profile" /></a>
                </div>
              </div>
            </div>
            <div className='col-lg-3 col-md-4 col-sm-6 col-12'>
              <div className="card">
                <div className='profile_img'>
                  <img src={CrypDOEbags} alt="profile" />
                </div>
                <h3>Phil</h3>
                <p>Communications</p>
                <div className='social_icon'>
                    <a href='https://twitter.com/officialdoebags' target="_blank"><img src={Twitter} alt="profile" /></a>
                  <a href='https://t.me/DOEbags' target="_blank"><img src={Telegram} alt="profile" /></a>
            </div>
          </div>
        </div>
        <div className='col-lg-3 col-md-4 col-sm-6 col-12'>
          <div className="card">
            <div className='profile_img'>
              <img src={Prash} alt="profile" />
            </div>
            <h3>Prash</h3>
            <p>Development</p>
            <div className='social_icon'>
              <a href='https://twitter.com/prashdoe' target="_blank"><img src={Twitter} alt="profile" /></a>
              <a href='https://t.me/prashcha' target="_blank"><img src={Telegram} alt="profile" /></a>
        </div>
      </div>
    </div><div className='col-lg-3 col-md-4 col-sm-6 col-12'>
        <div className="card">
          <div className='profile_img'>
            <img src={CP} alt="profile" />
          </div>
          <h3>Crypto President</h3>
          <p>Advisor</p>
          <div className='social_icon'>
              <a href='https://twitter.com/TheCryptoPrez' target="_blank"><img src={Twitter} alt="profile" /></a>
              <a href='https://t.me/thecryptopresident' target="_blank"><img src={Telegram} alt="profile" /></a>
          </div>
        </div>
      </div>
          </div>
        </div>
      </div>
      {/* our Promise */}
      <div className='our-promise'>
        <div className='container'>
        <div className='row justify-content-center'>
            <div className='col-lg-7 col-md-9 col-12 text-center'>
              <h3>Our Promise</h3>
              <p>We promise to deliver excellence and transparency in all we do. Our commitment to continuous improvement and growth as a project and community is unwavering, even in the face of challenges.</p>
            </div>
          </div>   
        </div>        
      </div>
    </div>
   );
}
 
export default AboutUs;