import React from "react";
import { Button } from "reactstrap";
import CoinSelectCard from "../../components/common/coinSelectCard/coinSelectCard";
//import { SwapWidget } from '@uniswap/widgets';
//import '@uniswap/widgets/fonts.css';
import { useEthereum } from "../../hooks/useEthereum"
// css
import './buy.scss';

const DOE_Token_List = [
  {
  "name": "Kudoe",
  "address": "0x5f190F9082878cA141F858c1c90B4C59fe2782C5",
  "symbol": "KDOE",
  "decimals": 18,
  "chainId": 1,
  "logoURI": "https://etherscan.io/token/images/kudoeio_32.png"
}
]
const Buy = () => {
  const { ethereum, getBestProvider, rpcURL } = useEthereum();
  const theme = {
    primary: '#0f2052',
    secondary: '#b796f0',
    interactive: '#d1e6f1',
    container: '#e8f0fa',
    module: '#FFF',
    accent: '#7b3fe4',
    outline: '#ABD6FE',
    dialog: '#FFF',
    fontFamily: 'Montserrat',
    borderRadius: 1,
  }
  return ( 
    <div>
      <div className="buy-outer">
        <div className="container">
        <div className="row justify-content-center">
            <div className="col-xxl-5 col-xl-6 col-lg-7 col-md-8 col-sm-12 col-12">
              <div className="buy-card">

          </div>
          </div>
          </div>
        </div>
      </div>
    </div>
   );  
}
 
export default Buy;