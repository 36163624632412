import React, { Component } from "react";


class Gleam extends Component {
  componentDidMount(){
    (function(d, s, id) { var js; if (d.getElementById(id)) {return;} js = d.createElement(s); js.id = id; js.src = "https://widget.gleamjs.io/e.js"; d.getElementsByTagName("head")[0].appendChild(js); }(document, "script", "GleamScript"));
  }
  render() { 
    return (
      <a class="e-widget no-button generic-loader" href="https://gleam.io/4plm7/kudoe-ctzn-pass-competition" rel="nofollow">Kudoe CTZN Pass Competition</a>
    );
  }
}
 
export default Gleam;

