import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { Fade } from "react-awesome-reveal";


// images
import KudoeTokenomics from '../../assets/images/home/kudoetokenomics.png'

const TokenomicsHomeTab = () => {
  return (
    <div>
      <div className='row'>
        <div className='col-xl-6 col-lg-5 col-md-12 col-sm-12 text-md-center mb-lg-0 mb-4'>
          <Fade triggerOnce={true} fraction={`0.6`}>
            <img src={KudoeTokenomics} alt="doe" className='img-fluid'/>
          </Fade>
        </div>
        <div className='col-xl-6 col-lg-7 col-md-12 col-sm-12'>
          <h2 className='common-heading mb-lg-4 mb-3'>KUDOE Tokenomics</h2>
          <Tabs
            defaultActiveKey="holders"
            id="justify-tab-example"
            className="custometab"
            justify
          >
            <Tab eventKey="holders" title="Non NFT Holders">
              <div className='row align-items-center'>
                <div className='col-12 ps-lg-5 pe-lg-5 mt-lg-4'>
                  <div className='hometabtext border-below'>
                    <div className='row'>
                      <div className='col-4'>
                      <Fade triggerOnce={true}>
                        <div className='buy'>
                          <p>Buy Tax</p>
                          <div>0<span>%</span></div>
                        </div>
                      </Fade>
                      </div>
                      <div className='col-8'>
                        <div className='listText'>
                        <Fade triggerOnce={true} cascade damping={0.2}>
                          <p><span>0%</span> Marketing</p>
                          <p><span>0%</span> Development</p>
                          <p><span>0%</span> Liquidity Wallet (Not auto liquidity)</p>
                          <p><span>0%</span> Manual Burn</p>
                        </Fade>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='hometabtext'>
                    <div className='row'>
                      <div className='col-4'>
                      <Fade triggerOnce={true}>
                        <div className='buy'>
                          <p>Sell Tax</p>
                          <div>5<span>%</span></div>
                        </div>
                      </Fade>
                      </div>
                      <div className='col-8'>
                        <div className='listText'>
                        <Fade triggerOnce={true} cascade damping={0.2}>
                          <p><span>1%</span> Marketing</p>
                          <p><span>2%</span> Development</p>
                          <p><span>1%</span> Liquidity Wallet (Not auto liquidity)</p>
                          <p><span>1%</span> Manual Burn</p>
                        </Fade>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Tab>
            <Tab eventKey="nonholders" title="NFT Holders (Only ETH)">
              <div className='row align-items-center'>
                <div className='col-12 ps-lg-5 pe-lg-5 mt-lg-4'>
                  <div className='hometabtext border-below'>
                    <div className='row'>
                      <div className='col-4'>
                      <Fade triggerOnce={true}>
                        <div className='buy'>
                          <p>Buy Tax</p>
                          <div>0<span>%</span></div>
                        </div>
                      </Fade>
                      </div>
                      <div className='col-8'>
                        <div className='listText'>
                        <Fade triggerOnce={true} cascade damping={0.2}>
                          <p><span>0%</span> Marketing</p>
                          <p><span>0%</span> Development</p>
                          <p><span>0%</span> Liquidity Wallet (Not auto liquidity)</p>
                          <p><span>0%</span> Manual Burn</p>
                        </Fade>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='hometabtext'>
                    <div className='row'>
                      <div className='col-4'>
                      <Fade triggerOnce={true}>
                        <div className='buy'>
                          <p>Sell Tax</p>
                          <div>3<span>%</span></div>
                        </div>
                      </Fade>
                      </div>
                      <div className='col-8'>
                        <div className='listText'>
                        <Fade triggerOnce={true} cascade damping={0.2}>
                          <p><span>1%</span> Marketing</p>
                          <p><span>1%</span> Development</p>
                          <p><span>0%</span> Liquidity Wallet (Not auto liquidity)</p>
                          <p><span>1%</span> Manual Burn</p>
                        </Fade>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Tab>
          </Tabs>
          <div className='row align-items-center'>
            <div className='col-12 ps-lg-5 pe-lg-5 mt-lg-4'>
              <div className='listText' style={{fontFamily: "Consolas"}}>
              <Fade triggerOnce={true} cascade damping={0.2}>
                <p><span>ETH:</span>0x5f190f9082878ca141f858c1c90b4c59fe2782c5</p>
                <p><span>BSC:</span>0xB9eEE0069bb54C2aA5762D184455686ec58A431F</p>
              </Fade>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>

  );
}

export default TokenomicsHomeTab;
