import { create } from "zustand"

export const useGlobalApr = create((set) => ({
  apr: 0,
  nftStakings: [],
  setGlobalApr: (val) =>
    set({
      apr: val,
    }),
  setNftStakings: (nftIds) => {
    console.log("newState=", nftIds)
    set({
      nftStakings: nftIds,
    })
  },
}))
