import React, { useEffect, useState } from "react"
import { useEthereum } from "../hooks/useEthereum"
import { shortAddress } from "../helpers/string"
import { useNavigate } from "react-router-dom"
import { useModal } from "../hooks/useModal"
import ConnectWalletModal from "./modal/ConnectWalletModal"

const ConnectWallet = ({ className, style }) => {
  const [connected, setConnected] = useState(false)
  const { ethereum, clearWallet } = useEthereum()
  const navigate = useNavigate()
  const { openModal } = useModal()
  const [loading, setLoading] = useState(false)
  const [isHovering, setIsHovering] = useState(false)

  useEffect(() => {
    const isConnected = !!ethereum.signer
    setConnected(isConnected)
  }, [ethereum.signer])

  const connect = () => {
    if (connected) {
      return navigate("/wallet")
    }
    openModal(<ConnectWalletModal />)
  }

  const disconnect = () => {
    console.log("calling disconnect function!")
    setLoading(true)
    clearWallet()
    setLoading(false)
  }

  return (
    <button
      onClick={() => (connected ? disconnect() : connect())}
      className={className}
      style={style}
    >
      <span className="text" style={{ color: "white" }}>
        {connected ? (
          <span
            onMouseEnter={() => setIsHovering(true)}
            onMouseLeave={() => setIsHovering(false)}
          >
            {isHovering ? "Disconnect Wallet" : shortAddress(ethereum.address)}
          </span>
        ) : (
          "Connect Wallet"
        )}
      </span>
    </button>
    // <>
    //   {!connected ? (
    //   ) : (
    //     <button
    //       // disabled={loading}
    //       onClick={disconnect}
    //       className={className}
    //       style={style}
    //     >
    //       <span className="text">Disconnect Wallet</span>
    //     </button>
    //   )}
    // </>
  )
}

export default ConnectWallet
