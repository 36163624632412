const url = "https://api.bash.finance/submit"

export const pushReferrals = async (hash) => {
  try {
    const ref = localStorage.getItem("__doe_ref")
    if (!ref) return
    const res = await fetch(url, {
      method: "POST",
      // mode: 'no-cors',
      headers: {
        "Content-Type": "application/json",
      },
      redirect: "manual",
      body: JSON.stringify({
        id: ref.toLowerCase(),
        hash: hash,
      }),
    })
    console.log("pushed ref", res)
  } catch (err) {
    console.error("referrals:", err)
  }
}

export const registerReferrer = async (address) => {
  const uri = `https://api.bash.finance/register`
  const res = await fetch(uri, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    redirect: "manual",
    body: JSON.stringify({
      address,
    }),
  })
  if (res.status !== 200) throw new Error("failed")
  return
}
